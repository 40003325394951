<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl py-0 px-3>
          <v-flex xs2 sm1 pt-3 offset-sm11 offset-xs10 ref="actionBar">
              <div class="text-right">
                  <v-btn-toggle
                          color="primary"
                          dense
                          multiple
                  >
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                  <v-btn @click="createDialog =true">
                      <v-icon small dark v-on="on">ti ti-plus</v-icon>
                  </v-btn>
                  </template>
                  <span>{{ $t('message.createNew') }}</span>
                  </v-tooltip>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                              v-on="{...tooltip, ...menu}">
                              <v-icon small dark v-on>ti ti-export</v-icon>
                          </v-btn>
                      </template>
                      <span>{{ $t('message.export') }}</span>
                    </v-tooltip>
                  </template>
                      <v-list>
                          <v-list-item
                          @click="">
                              <v-list-item-title>{{ $t('message.asPdf') }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="">
                              <v-list-item-title>{{ $t('message.asExcel') }}</v-list-item-title>
                          </v-list-item>
                      </v-list>
                  </v-menu>
              </v-btn-toggle>
              </div>
          </v-flex>
            <v-layout row wrap>
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        class="elevation-1 appic-table"
                        :footer-props="pagination"
                        :key="tableKey"
                        :items-per-page="25"
                        dense
                        calculate-widths
                        fixed-header
                        :height="tableheight"
                    >
                        <template v-slot:item="props">
                            <tr>
                                <td @click="createDialog = true">{{ props.item.title }}</td>
                                <td width="100px">
                                    <v-edit-dialog
                                            :return-value.sync="props.item.status"
                                            large
                                            persistent
                                            @save="save"
                                            @cancel="cancel"
                                            @open="open"
                                            @close="close"
                                    >
                                        <div class="text-center">
                                            <v-chip :color="getStatusColor(props.item.status)" dark x-small>{{ props.item.status }}</v-chip>
                                        </div>
                                        <template v-slot:input>
                                            <div class="mt-4 title">{{ $t('message.updateStatus') }}</div>
                                        </template>
                                        <template v-slot:input>
                                            <v-select
                                                    v-model="props.item.status"
                                                    :items="filters.statuses"
                                                    :placeholder="$t('message.status')"
                                                    label="Edit"
                                                    clearable
                                            ></v-select>
                                        </template>
                                    </v-edit-dialog>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
                v-model="createDialog"
                transition="dialog-bottom-transition"
                overlay=false
                scrollable
                max-width="600px"
                width="600px"
        >
            <v-card>
                <v-card-title>
                    <v-toolbar
                            color="success"
                            dark
                            flat
                            dense
                    >
                        <v-btn icon @click.native="createDialog = false" dark>
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t('message.createInternalRemark') }}</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md class="dense-inputs">
                        <v-form
                                ref="form"
                                lazy-validation>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.title') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.title"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="internalremark.title ? '' : $t('message.required')"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                            <v-layout row>
                                <v-flex xs12 pl-5 py-1 class="font-weight-bold">{{ $t('message.freeText1') }}</v-flex>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.element') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext1"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.type') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-select
                                            v-model="internalremark.freetext1_type"
                                            solo
                                            dense
                                            required
                                            :rules="[v => !!v || '']"
                                            hide-details
                                            :items="filters.types"
                                    ></v-select>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.formLabel') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext1_label"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.textPrefix') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext1_prefix"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                            <v-layout row>
                                <v-flex xs12 pl-5 py-1 class="font-weight-bold">{{ $t('message.freeText2') }}</v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.element') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext2"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.type') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-select
                                            v-model="internalremark.freetext2_type"
                                            solo
                                            dense
                                            :items="filters.types"
                                            hide-details
                                    ></v-select>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.formLabel') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext2_label"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.textPrefix') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext2_prefix"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                            <v-layout row>
                                <v-flex xs12 pl-5 py-1 class="font-weight-bold">{{ $t('message.freeText3') }}</v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.element') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext3"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.type') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-select
                                            v-model="internalremark.freetext3_type"
                                            solo
                                            dense
                                            :items="filters.types"
                                            hide-details
                                    ></v-select>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.formLabel') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext3_label"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.textPrefix') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext3_prefix"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                            <v-layout row>
                                <v-flex xs12 pl-5 py-1 class="font-weight-bold">{{ $t('message.freeText4') }}</v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.element') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext4"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.type') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-select
                                            v-model="internalremark.freetext4_type"
                                            solo
                                            dense
                                            :items="filters.types"
                                            hide-details
                                    ></v-select>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.formLabel') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext3_label"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.textPrefix') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="internalremark.freetext4_prefix"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error lighten-2" @click.stop="close">{{$t('message.close')}}</v-btn>
                    <v-btn color="primary lighten-2" @click.save="save">{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { api } from "../../../api";
    import {mapGetters} from "vuex";
    import {mapActions} from "vuex";
    export default {
        name: "InternalRemarks",
        data() {
            return {
                loader: true,
                search: "",
                createDialog: false,
                headers: [
                    {
                        text: "Internal Remark",
                        value: "title"
                    },
                    {
                        text: "Status",
                        value: "status",
                        class: "text-center"
                    }
                ],
                watch: {
                  params: {
                    handler() {
                      this.getDataFromApi().then(data => {
                        console.log("GETDATA");
                        this.items = data.items;
                      })
                    },
                    deep: true
                  }
                },
                items: [],
                filters: {
                    types: [
                        {value: "date", text: "Date (dd/mm/yyyy)"},
                        {value: "text", text: "Text"},
                        {value: "week", text: "Week"}
                    ]
                },
                pagination: {
                    page: 1,
                    itemsPerPageAllText: "$vuetify.dataFooter.itemsPerPageAll",
                    itemsPerPageOptions: [25, 40, 60, -1]
                },
                tableKey: 0,
                tableheight: "100vh",
                filters: {
                    statuses: [
                        {text: 'Active', value: 0},
                        {text: 'Suspended', value: 1}
                    ],
                },
                attemptSave: false,
                internalremark: [],
            }
        },
        computed: {
          params(nv) {
            return {
              query: this.search
            };
          }
        },
        methods: {
            handleResize() {
                this.tableheight = window.innerHeight - (137 + this.$refs.breadcrumbBar.clientHeight + this.$refs.actionBar.clientHeight);
            },
            getTableData() {
                api
                    .get("appic/getinternalremarks")
                    .then(response => {
                        this.loader = false
                        this.items = response.data
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            getDataFromApi() {
              this.laoding = true;
              return new Promise((resolve) => {
                let items = this.getItems();
                setTimeout(() => {
                  this.loading = false;
                  resolve({
                    items,
                  });
                });
              });
            },
            getStatusColor(status) {
                if (status == 'Active') return 'green'
                else return 'grey'
            },
            save () {
                if(this.$refs.form.validate()) {
                  this.createDialog = false;
                  this.$refs.form.reset();
                }
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Data saved'
            },
            cancel () {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Canceled'
            },
            open () {
                this.snack = true
                this.snackColor = 'info'
                this.snackText = 'Dialog opened'
            },
            close () {
                this.createDialog = false;
                this.$refs.form.reset()
            },
        },
        mounted (){
            this.getTableData();
            this.handleResize()
            this.getDataFromApi().then(data => {
              this.items = data.items;
            });
        },
        created() {
            window.addEventListener('resize', this.handleResize)
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
    }
</script>

<style scoped>

.hasError & {
  background-color: pink;
}

</style>
